import React, { useContext } from 'react';
import { SwiperComponent } from 'vitl-component-library';

import { Link } from 'gatsby';
import Image from '@components/image/image';
import Loader from '../loader';
import { GlobalContext } from '@store/global-state';
import { formatCurrency, formatPrice } from '../../services/format';
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';

import styles from './recommended-products.module.scss';
import { OUT_OF_STOCK } from '../../constants/messages';

interface IProps {
  title: string;
  items: item[];
}

interface item {
  path: string;
  filename_disk: string;
  title: string;
  button_label: string;
  identifier: string;
  price: string;
}

const RecommendedProducts: React.FC<IProps> = ({ title, items }) => {
  const { currencyCode } = useContext(GlobalContext);
  const currency = currencyCode && formatCurrency(currencyCode);

  const getPrice = (item: item) => {
    const { dataPlans, loading: loadingPlans, isAvailable } = useGetProductPlansData(item.identifier);

    if (loadingPlans) {
      return <Loader />;
    }

    if (!isAvailable) {
      return OUT_OF_STOCK;
    }

    const price = item?.price;

    if (price) {
      return `From ${currency}${price}`;
    }

    const productPrice =
      dataPlans?.product_plans?.length > 1
        ? dataPlans?.product_plans?.find((plan: any) => plan['planLabel'].toLowerCase() === 'monthly subscription')
          ?.rrpPrice
        : dataPlans?.product_plans[0]?.rrpPrice;
    const productCurrency = dataPlans?.product_plans && dataPlans.product_plans[0]?.currency;

    if (productPrice && productCurrency) {
      return `From ${formatPrice(productPrice, productCurrency)}`;
    }
    return 'Back in stock soon';
  };

  return (
    <section className={styles.section}>
      <h2>{title}</h2>

      <hr className={styles.divider} />
      <div className={styles.swiperWrapper}>
        <SwiperComponent
          paginationType="bullets"
          spaceBetween={100}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: items.length === 3 ? 3 : 2,
            },
          }}
        >
          {items?.map((item: item, slideIndex: number) => (
            <div key={slideIndex} className={styles.item}>
              <Link to={item.path} className={styles.image}>
                <Image filename={item.filename_disk || item.image} width={360} />
              </Link>
              <h3>{item.title}</h3>
              <hr className={styles.divider} />
              <p>{getPrice(item)}</p>
              <Link to={item.path} className={styles.buttonInverted}>
                {item.button_label}
              </Link>
            </div>
          ))}
        </SwiperComponent>
      </div>
    </section>
  );
};

export default RecommendedProducts;
